<template>
  <div class="mine-contract pb-50 bg-color pa-16">
    <Navbar title="合同"> </Navbar>
    <div v-if="contractList.length">
      <MessageCard v-for="(item, index) in contractList" :cardDetail="item" :key="index" />
    </div>
    <div v-else>
      <Empty imgWidth="0.84rem" :image="noContract" description="当前无合同" />
    </div>

    <van-button
      v-if="isCarrierContract && contractList.length"
      type="primary"
      size="medium"
      class="fixed-footer-btn"
      @click="signCarrierContract"
      :loading="carrierBtnLoading"
      :disabled="carrierBtnDisabled"
    >
      签约
    </van-button>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api';
import { Button, Toast } from 'vant';
import MessageCard from '../components/messageCard';
import { apiGetConListByBatch, signMsmAgreeApi } from '@api/mine';
import { useRouterQuery } from '@hook/useRouterQuery';
import { useRouter } from '@/utils/compsitionHack';
import { PageEnum } from '@/enums/pageEnum';
import { CARRIER_KEY } from './constants';

export default defineComponent({
  name: 'MineContract',
  components: {
    Button,
    MessageCard
  },
  setup() {
    const noContract = require('@/assets/htkb.png');
    const query = useRouterQuery();
    const router = useRouter();
    const contractList = ref([]);

    function setContractStateDone(list) {
      return list.map(item => {
        item.state = 2;
        return item;
      });
    }

    // 承运人合同加在当前页面
    const setContractFlag = () => {
      window.localStorage.setItem(`${CARRIER_KEY}${query.batchNo}`, 1);
    };

    const canSignContract = () => {
      return window.localStorage.getItem(`${CARRIER_KEY}${query.batchNo}`) ?? false;
    };

    const isCarrierContract = query.type === '1' || query.type === '9';
    const carrierBtnLoading = ref(false);
    const carrierBtnDisabled = ref(false);

    const hasSigned = canSignContract();
    carrierBtnDisabled.value = hasSigned ? true : false;

    const signCarrierContract = async () => {
      try {
        carrierBtnLoading.value = true;
        const res = await signMsmAgreeApi({
          batchNo: query.batchNo,
          t: query.t,
          u: query.u,
          type: query.type
        });
        if (res) {
          setContractFlag();
          carrierBtnDisabled.value = true;
          Toast('签约成功');
          contractList.value = setContractStateDone(contractList.value);
        } else {
          // 404 走这里？？？
          carrierBtnLoading.value = false;
          carrierBtnDisabled.value = false;
        }
      } catch (error) {
        Toast.fail(error);
        carrierBtnDisabled.value = false;
      } finally {
        carrierBtnLoading.value = false;
      }
    };

    onMounted(async () => {
      // 委托代收协议 直接去了详情
      if (query.type === '3') {
        router.push({
          path: PageEnum.AGREEMENT,
          query: {
            type: query.type,
            batchNo: query.batchNo,
            contractId: query.contractId,
            t: query.t,
            u: query.u
          }
        });
        contractList.value = [];
      } else {
        // 承运人合同
        apiGetConListByBatch({
          batchNo: query.batchNo,
          type: query.type,
          t: query.t,
          u: query.u
        }).then(res => {
          if (hasSigned) {
            contractList.value = setContractStateDone(res.data);
          } else {
            contractList.value = res.data;
          }
        });
      }
    });

    return {
      noContract,
      contractList,

      isCarrierContract,
      carrierBtnLoading,
      carrierBtnDisabled,
      signCarrierContract
    };
  }
});
</script>
<style lang="less">
.mine-contract {
  min-height: 100vh;
  .switch-taps {
    position: absolute;
    top: 0.44rem;
    width: 100vw;
    left: 0;
    .van-tab--active {
      color: #0076ff !important;
    }
    .van-tabs__line {
      width: 50%;
      background-color: #0076ff;
      height: 1px;
    }
    .van-tab__pane {
      .content {
        top: 0.8rem;
      }
    }
  }

  .fixed-footer-btn {
    position: fixed;
    bottom: 12px;
    width: 92%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
</style>
