<template>
  <div @click.stop="() => jumpPdf(cardDetail)" class="constract-card pa-16 mb-16 d-flex bda-4 justify-space-between">
    <div class="mb-24 d-flex flex-column justify-space-between">
      <div>
        <span class="font-16 fw-500">{{ cardDetail.companyName }}</span>
      </div>
      <div class="text_muted">
        {{ cardDetail.contractName }}
      </div>
    </div>
    <Icon name="jt-h" svgClass="mt-12" />
    <div class="con-status">
      <div class="text_success px-16" style="background-color: rgba(43,173,64,0.10)" v-if="cardDetail.state === 2">
        已签
      </div>
      <div v-else class="text_warning px-16" style="background-color: rgba(250,140,22,0.10)">
        未签
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { Tag, Button } from 'vant';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import ClientApp from '@/client/index';
import { useRouterQuery } from '@hook/useRouterQuery';
import { useRouter } from '@/utils/compsitionHack';
import { apiConDetail } from '@api/mine';
import { PageEnum } from '@/enums/pageEnum';

export default defineComponent({
  name: 'ConstractCard',
  components: {
    Tag,
    Button
  },
  props: {
    cardDetail: {
      type: Object
    }
  },
  setup() {
    const dateFormat = (time, format) => {
      return formatToDateTime(time, format);
    };
    const query = useRouterQuery();
    const router = useRouter();
    const jumpPdf = cardDetail => {
      if (cardDetail.status !== 2) {
        router.push({
          path: PageEnum.AGREEMENT,
          query: {
            batchNo: query.batchNo,
            contractId: cardDetail.id,
            t: query.t,
            type: query.type
          }
        });
      } else {
        // apiConDetail({
        //   batchNo: query.batchNo,
        //   contractId: cardDetail.id
        // }).then(res => {
        //   ClientApp.previewFile({
        //     url: res
        //   });
        // });
      }
    };
    return {
      dateFormat,
      jumpPdf
    };
  }
});
</script>
<style lang="less">
.constract-card {
  background-color: white;
  height: 0.72rem;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 6px 0px rgba(223, 233, 245, 0.2);
  .con-status {
    position: absolute;
    right: -16px;
    top: 4px;
    transform: rotate(45deg);
  }
}
</style>
